import React from "react"
import { useTranslation } from "react-i18next"

const NewFlag = ({ className, type = "overview" }) => {
  const position = { card: "-top-3 -left-3", overview: "-top-5 -left-5" }
  const textSize = {
    card: "text-base",
    overview: "text-2xl",
    gridCard: "text-base",
    smallCard: "text-lg md:text-xl lg:text-base xl:text-sm",
  }
  const shadow = { card: "shadow-md", overview: "shadow-lg" }
  const { t } = useTranslation()
  return (
    <div
      className={`${className} ${position[type]} ${textSize[type]} absolute w-1/4 min-w-[3rem] bg-transparent h-auto aspect-square inset-0 z-10 text-white flex justify-center items-center`}
    >
      <div
        className={`${shadow[type]} w-[200%] h-1/2 min-h-[1.5rem] absolute bg-primary z-10 -rotate-45 flex justify-center items-center font-bold shadow-black/[15%]`}
      >
        {t("generic.new_flag.new_article")}
      </div>
    </div>
  )
}

export default NewFlag
