import { useState, useEffect } from "react"

function useDateCheck(start, end, isNew) {
  const [fullfilled, setfullfilled] = useState(undefined)
  useEffect(() => {
    function convertDateFormat(dateString) {
      const [datePart, timePart] = dateString.split(" ")
      const [day, month, year] = datePart.split(".")
      const [hours, minutes, seconds] = timePart.split(":")

      const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`
      return isoDateString
    }
    if (!isNew) {
      setfullfilled(false)
    } else {
      const tzname = "Europe/Berlin"
      const parsedStart = convertDateFormat(start)
      const parsedEnd = convertDateFormat(end)

      const longOffsetFormatter = new Intl.DateTimeFormat("de-DE", {
        timeZone: tzname,
        timeZoneName: "longOffset",
      })
      const longOffsetStringStart = longOffsetFormatter.format(
        new Date(parsedStart)
      )
      const longOffsetStringEnd = longOffsetFormatter.format(
        new Date(parsedEnd)
      )
      const gmtOffsetStart = longOffsetStringStart.split("GMT")[1]
      const gmtOffsetEnd = longOffsetStringEnd.split("GMT")[1]
      const startDate = new Date(parsedStart + gmtOffsetStart)
      const endDate = new Date(parsedEnd + gmtOffsetEnd)
      const currentDate = new Date()
      setfullfilled(currentDate >= startDate && currentDate <= endDate)
    }
  }, [start, end, isNew])

  return fullfilled
}

export default useDateCheck
