import React, { useState, useRef, useEffect } from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import CloudinaryVideoPlayer from "../modules/CloudinaryVideoPlayer"
import CloudinaryVideoThumbnail from "../molecules/CloudinaryVideoThumbnail"
import { Dialog, Transition } from "@headlessui/react"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"

import { CloseOutline20, CaretLeft16, CaretRight16 } from "@carbon/icons-react"

const MissingImage = ({ imageUrl }) => {
  return (
    <div className="w-full py-4 lg:w-1/2 px-grid">
      <div className="block w-full h-full overflow-hidden border border-gray-100 group">
        <Image
          fillColor="ffffff"
          aspectRatio="1by1"
          className="w-full h-auto"
          image={{ filename: imageUrl }}
          forceLoad={true}
        />
      </div>
    </div>
  )
}

const ProductThumbnail = ({ imageUrl, className, index, clickHandler }) => {
  return (
    <div className={className}>
      <button
        className="block w-full h-full overflow-hidden border border-gray-100 group"
        onClick={() => clickHandler(index)}
      >
        <Image
          fillColor="ffffff"
          aspectRatio="1by1"
          className="w-full h-auto duration-300 transform group-hover:scale-102"
          image={imageUrl}
          forceLoad={true}
        />
      </button>
    </div>
  )
}

const ProductImageSlider = ({ block }) => {
  const { i18n } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeModalImage, setActiveModalImage] = useState(-1)

  const openModalHandler = (marker) => {
    setActiveModalImage(marker)
    setIsModalOpen(true)
  }

  const closeModalHandler = () => {
    setActiveModalImage(-1)
    setIsModalOpen(false)
  }

  // Slick Slider

  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1055,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 671,
        settings: {
          infinite: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let relevantVideos = []

  if (block.product_videos && block.product_videos.length > 0) {
    if (i18n.language === "de") {
      relevantVideos = block.product_videos
    }
    // filter on video ending with DE, in any other language use EN
    // if (i18n.language === "de") {
    //   relevantVideos = block.product_videos.filter((videoFilename) => {
    //     return videoFilename.toLowerCase().split(".")[0].endsWith("de")
    //   })
    // } else {
    //   relevantVideos = block.product_videos.filter((videoFilename) => {
    //     return videoFilename.toLowerCase().split(".")[0].endsWith("en")
    //   })
    // }
  }

  let galleryObjects = block.product_images.map((img) => {
    if (img === "undefined") {
      return { type: "noimage", url: process.env.GATSBY_IMG_PLACEHOLDER }
    } else {
      return { type: "image", url: img }
    }
  })

  // append video(s) if it fits in the total 4 items limit
  if (galleryObjects.length + relevantVideos.length <= 4) {
    galleryObjects.push(
      ...relevantVideos.map((video) => {
        return { type: "video", url: video }
      })
    )
  } else {
    // otherwise insert the videos
    relevantVideos.forEach((video, index) => {
      galleryObjects.splice(4 - relevantVideos.length, 0, {
        type: "video",
        url: video,
      })
    })
  }

  return (
    <>
      <Slider
        className="flex flex-row flex-wrap mb-8 md:mb-0 md:-my-4 -mx-grid"
        {...settings}
      >
        {galleryObjects.length > 0 &&
          galleryObjects.map((obj, index) =>
            index < 1 ? (
              <React.Fragment key={obj.url}>
                {obj.type === "image" && (
                  <ProductThumbnail
                    className="w-full py-4 px-grid"
                    imageUrl={obj.url}
                    index={index}
                    clickHandler={openModalHandler}
                  />
                )}
                {obj.type === "noimage" && <MissingImage imageUrl={obj.url} />}
                {obj.type === "video" && (
                  <CloudinaryVideoThumbnail
                    videoFilename={obj.url}
                    index={index}
                    clickHandler={openModalHandler}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment key={obj.url}>
                {obj.type === "image" && (
                  <ProductThumbnail
                    className="w-full py-4 lg:w-1/3 xl:w-1/4 px-grid"
                    imageUrl={obj.url}
                    index={index}
                    clickHandler={openModalHandler}
                  />
                )}
                {obj.type === "video" && (
                  <CloudinaryVideoThumbnail
                    videoFilename={obj.url}
                    index={index}
                    clickHandler={openModalHandler}
                  />
                )}
              </React.Fragment>
            )
          )}
      </Slider>

      <Transition appear show={isModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className=" fixed inset-0 z-10 overflow-y-auto"
          onClose={() => closeModalHandler()}
        >
          <Dialog.Overlay className="fixed flex inset-0 bg-black bg-opacity-50">
            <div className="block-w-container w-full self-center">
              {activeModalImage > -1 && (
                <>
                  {galleryObjects &&
                    galleryObjects[activeModalImage].type === "image" && (
                      <div className="h-[calc(100vh_-_4rem)] bg-white relative m-4">
                        <button
                          className="absolute right-5 top-5"
                          onClick={() => closeModalHandler()}
                        >
                          <CloseOutline20 />
                        </button>
                        <div className=" w-full h-full p-8">
                          <Image
                            className="object-contain w-full h-full"
                            image={galleryObjects[activeModalImage].url}
                            imageWidth={1500}
                          />
                        </div>
                      </div>
                    )}
                  {galleryObjects &&
                    galleryObjects[activeModalImage].type === "video" && (
                      <div className="w-full bg-white relative">
                        <button
                          className="absolute right-5 top-5 z-20"
                          onClick={() => closeModalHandler()}
                        >
                          <CloseOutline20 className="text-white" />
                        </button>
                        <CloudinaryVideoPlayer
                          videoFilename={galleryObjects[activeModalImage].url}
                        />
                      </div>
                    )}
                </>
              )}
            </div>
          </Dialog.Overlay>
        </Dialog>
      </Transition>
    </>
  )
}

export default ProductImageSlider
