import React from "react"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { Cloudinary } from "@cloudinary/url-gen"

import { PlayOutline32 } from "@carbon/icons-react"

const CloudinaryVideoThumbnail = ({ videoFilename, index, clickHandler }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.CLOUDINARY_NAME,
    },
  })

  const stillImage = cld
    .video(videoFilename.split(".")[0])
    .format("jpg")
    .toURL()
  return (
    <div className="w-full py-4 lg:w-1/3 xl:w-1/4 px-grid">
      <button
        className="block w-full h-full overflow-hidden border border-gray-100 group relative"
        onClick={() => clickHandler(index)}
      >
        <Image
          fillColor="ffffff"
          aspectRatio="1by1"
          className="w-full h-auto duration-300 transform group-hover:scale-102"
          image={stillImage}
          forceLoad={true}
        />
        <PlayOutline32 className="h-28 w-28 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white" />
      </button>
    </div>
  )
}

export default CloudinaryVideoThumbnail
